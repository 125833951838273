import { getToken } from 'helpers/Customer';
import { useEffect } from 'react';
import { getIcid, getSourceCode } from '@bbnb/openfit-frontend-shared';

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

type GTMData = Record<string, any>;

// FIXME: Set to false for merge/deployment
const DEBUG = false;

const getPageName = () =>
  `sell-${
    window.location.pathname.split('/').filter(Boolean).slice(-1)[0] || 'home'
  }`;

const getGeneralValues = () => {
  return {
    page_type: 'product', //Suggested
    country_code: 'US', //Suggested
    icid: getIcid(), //Suggested
    language_code: 'en', //Suggested
    page_name: getPageName(), //Required
    site_section: 'SHOP', //Required
    source_code: getSourceCode(), //Suggested
    origin: 'web',
    platform: 'web',
  };
};

const getCustomerValues = () => {
  const token = getToken();

  return {
    customer_account_id: token ? token.sub : 'N/A', //Required
    customer_profile_id: token ? `${token?.sub}-1` : 'N/A', //Required
    email: token ? token?.email : 'N/A',
  };
};

const tag = (GTMData: GTMData) => {
  const GTMSiteData = {
    general: {
      ...getGeneralValues(),
    },
    customer: {
      ...getCustomerValues(),
    },
    ...GTMData,
  };

  return new Promise((resolve) => {
    DEBUG && console.log('[GTM] GTMSiteData', GTMSiteData);

    window.dataLayer.push({
      ...GTMSiteData,
      eventCallback: (containerId: string, result: any, another: any) => {
        DEBUG &&
          console.log('[GTM] containerId / result: ', containerId, another);
        DEBUG &&
          console.log('[GTM] Stringify result: ', JSON.stringify(result));
        setTimeout(() => resolve(result), 100);
      },
      eventTimeout: 2000,
    });
  });
};

export const tagStandardPageView = (
  eventName: string,
  eventProperties: GTMData = {}
) => {
  try {
    return tag({
      ...eventProperties,
      event: eventName, //Required
    });
  } catch (e) {
    console.error('[GTM] Error:', e);
  }

  return false;
};

export const useGTMTagStandardPageView = (
  eventName: string,
  eventProperties: GTMData = {}
) => {
  useEffect(() => {
    tagStandardPageView(eventName, eventProperties);
  }, [eventName, eventProperties]);
};

export const GTMTagSellPageView = (GTMData: GTMData = {}) =>
  tagStandardPageView('sell_page_view', { event_data: GTMData });

export const GTMTagLinkClick = (eventName = 'N/A', GTMData: GTMData = {}) => {
  try {
    return tag({
      ...GTMData,
      event: eventName,
    });
  } catch (e) {
    console.error('[GTM] Error:', e);
  }

  return Promise.resolve();
};

export const GTMTagCartAdd = (
  products: {
    id?: string;
    name?: string;
    price?: number;
    sku?: string;
    currencyCode?: string;
  }[]
) => {
  return GTMTagLinkClick('cart_add', {
    cart: {
      cart_product_id: products.map((p) => p.id),
      cart_product_variant_id: products.map((p) => p.sku),
      cart_product_variant_price: products.map((p) => p.price),
      cart_product_variant_currency_code: products.map(
        (p) => p.currencyCode || 'USD'
      ),
      cart_product_variant_quantity: products.map((p) => 1),
      cart_product_variant_sku: products.map((p) => p.sku),
      cart_total_items: products.length,
      cart_total_value: products.reduce(
        (sum, p): number => sum + (p.price || 0),
        0
      ),
    },
    product: {
      product_name: products.map((p) => p.name),
      product_variant_id: products.map((p) => p.sku),
      product_variant_price: products.map((p) => p.price),
      product_variant_currency_code: products.map(
        (p) => p.currencyCode || 'USD'
      ),
    },
  });
};
