import React, { ReactElement } from 'react';
import { mapAndFormatSearchParams } from 'helpers/Amplitude';
import { VideoPlayButton } from 'components/Common/Button';
import { PlayButtonWrapper } from './YoutubeModal.styles';

export const renderYoutubeModalButton = (
  playButtonHandler: (e: MouseEvent) => Promise<any>
): ReactElement => (
  <PlayButtonWrapper>
    <VideoPlayButton onClick={playButtonHandler} />
  </PlayButtonWrapper>
);

export const generateEmbedLink = (url = '') =>
  url.includes('vimeo')
    ? generateVimeoEmbedLink(url)
    : generateYoutubeEmbedLink(url);

export const generateVimeoEmbedLink = (url: string): string => {
  if (url.includes('player')) {
    return `${url}&autoplay=1`;
  }
  const videoId = url.split('/').pop();

  return `https://player.vimeo.com/video/${videoId}?autoplay=1`;
};

export const generateYoutubeEmbedLink = (url: string): string => {
  if (url.includes('embed')) {
    return url + '?autoplay=1';
  }

  const videoId = mapAndFormatSearchParams(new URLSearchParams(url)).get(
    'https://www.youtube.com/watch?v'
  );

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  }

  return '';
};
