import React from 'react';
import { useKeenSlider } from 'keen-slider/react';

import { ProductTile } from 'components/Product/Tile/ProductTile';

import { ProductSliderProps } from './ProductSlider.model';
import {
  SliderContainer,
  DotsContainer,
  Dot,
  SliderContentHoverShadowFix,
  FullSliderContainer,
  TileHoverShadowFix,
} from './ProductSlider.styles';
import { DesktopOnlyArrow, SliderArrows } from 'components/Slider/SliderArrows';

export const ProductSliderMobile: React.FC<ProductSliderProps> = ({
  products,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 2,
    spacing: 14,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  return (
    <SliderContainer>
      <div
        className="keen-slider"
        ref={ref}
        style={{ visibility: slider ? 'visible' : 'hidden' }}
      >
        {products.map((product, idx) => (
          <div key={idx} className="keen-slider__slide">
            <ProductTile {...product} />
          </div>
        ))}
      </div>

      {slider && (
        <DotsContainer>
          {[...Array(slider.details().size - 1).keys()].map(
            (slideIndex): React.ReactElement => (
              <Dot
                data-test-id="dot"
                key={slideIndex}
                onClick={(): void => {
                  slider.moveToSlide(slideIndex);
                }}
                isActive={slideIndex === currentSlide}
              />
            )
          )}
        </DotsContainer>
      )}
    </SliderContainer>
  );
};

export const FullProductSliderMobile: React.FC<
  ProductSliderProps & {
    inRow: number;
  }
> = ({ products, inRow }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: inRow,
    spacing: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });
  const dots = [...Array(Math.ceil(products.length / inRow)).keys()];

  const productsCount = products.length;
  return (
    <DesktopOnlyArrow>
      <SliderArrows slider={slider}>
        <FullSliderContainer>
          <SliderContentHoverShadowFix>
            <div
              className="keen-slider"
              ref={ref}
              style={{ visibility: slider ? 'visible' : 'hidden' }}
            >
              {products.map((product, idx) => (
                <div
                  key={idx}
                  className="keen-slider__slide"
                  style={{ zIndex: productsCount - idx }}
                >
                  <TileHoverShadowFix>
                    <ProductTile {...product} />
                  </TileHoverShadowFix>
                </div>
              ))}
            </div>
          </SliderContentHoverShadowFix>

          {slider && (
            <DotsContainer>
              {dots.map(
                (slideIndex): React.ReactElement => (
                  <Dot
                    data-test-id="dot"
                    key={slideIndex}
                    onClick={(): void => {
                      slider.moveToSlide(slideIndex);
                    }}
                    isActive={slideIndex === currentSlide}
                  />
                )
              )}
            </DotsContainer>
          )}
        </FullSliderContainer>
      </SliderArrows>
    </DesktopOnlyArrow>
  );
};
