import styled from 'styled-components';
import { space, fontSize, lineHeight } from 'styled-utils';
import { CommonTitle } from 'components/Pdp';

export const Content = styled.div`
  ${space({ mx: ['auto', 'auto', '109px'], mt: ['35px', '35px', 0] })};
  max-width: 360px;
`;

export const Title = CommonTitle;

export const Description = styled.div`
  ${space({ mx: ['12px', 'auto'] })};

  p {
    ${fontSize({ fontSize: ['14px', '18px'] })};
    ${lineHeight({ lineHeight: ['20px', '24px'] })};
  }

  li {
    margin-bottom: 0.5em;
  }

  li > p {
    margin: 0;
  }
`;
