import styled from 'styled-components';
import { variant, space, SpaceProps } from 'styled-system';
import { height, HeightProps } from 'styled-utils';
import { GradientBackgroundColor } from './Background.model';
import { gradients } from './Background.helper';
import PlaySVG from './play.svg';
import PlayVideo from 'svg/play-video.svg';

export const Wrapper = styled.div<HeightProps & SpaceProps>`
  ${height};
  ${space};
  width: 100%;
  position: relative;
  display: flex;
  z-index: 2;
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  height: 100%;
  width: 100%;
`;

export const Video = styled.video<{ opacity?: number }>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ opacity }) => opacity ?? 1};
`;

export const PlainPlayButton = styled.div<{
  alternateButton?: boolean;
}>`
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  background-image: url(${({ alternateButton }) =>
    alternateButton ? PlayVideo.url : PlaySVG.url});
`;

export const PlayButton = styled(PlainPlayButton)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const GradientBg = styled(Wrapper)<{ variant: GradientBackgroundColor }>`
  ${variant({
    variants: {
      ...gradients,
    },
  })};
`;
