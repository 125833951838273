const EMAIL_REQUIRED = 'Email is required';
const EMAIL_INVALID = 'Please enter your valid email address';
const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function testEmail(email?: string | null) {
  return emailRegEx.test(email?.toLowerCase() as string);
}
const isEmailValid = (email: string) => testEmail(email);

export const getEmailError = (needCheckEmail: boolean, email: string) => {
  if (!needCheckEmail) return null;
  if (!email && email.length <= 0) return EMAIL_REQUIRED;
  if (!isEmailValid(email)) return EMAIL_INVALID;

  return null;
};
