import styled from 'styled-components';
import { GatsbyImage } from './GatsbyImage';

export const ImageWrapper = styled.div<{
  fullWidth: boolean;
  mobile: { width: number; height: number };
}>`
  z-index: 0;
  .gatsby-image-wrapper {
    position: ${({ fullWidth }) =>
      fullWidth ? 'static !important' : 'relative'};
  }
  [role='presentation'] {
    @media screen and (max-width: 40em) {
      ${({ mobile }) => contentUrl(mobile)};
    }
  }
`;

export const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: inline-flex;
`;

const contentUrl = (mobile: { width: number; height: number }) =>
  `content: url("data:image/svg+xml;charset=utf-8,%3Csvg height='${mobile.height}' width='${mobile.width}' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E");`;
