import { ScreenAware } from 'components/Common/Placement/Placement';
import { SanityImage } from 'graphql-types';
import { PaddingTopProps } from 'styled-system';

export interface ResponsiveBackgroundImageProps extends PaddingTopProps {
  height?: ScreenAware<number | string | undefined | null>;
  images: ScreenAware<SanityImage>;
  lockAspectRatio?: boolean;
  children: React.ReactNode;
}

export interface BackgroundVideoProps extends PaddingTopProps {
  poster?: string;
  video: string;
  height?: number;
  videoTint?: number;
  children: React.ReactNode;
}

export enum GradientBackgroundColor {
  PinkLemonade = 'PinkLemonade',
  WildBerry = 'WildBerry',
  MiamiVice = 'MiamiVice',
  StrawberryLemonade = 'StrawberryLemonade',
  BerryMix = 'BerryMix',
  BerryLemonade = 'BerryLemonade',

  MangoTango = 'MangoTango',
  OrangeCrush = 'OrangeCrush',
  GrapefruitSunrise = 'GrapefruitSunrise',
  StrawberryKiwi = 'StrawberryKiwi',

  Appletini = 'Appletini',
  BlueLagoon = 'BlueLagoon',
  Curacao = 'Curacao',
  BlueHawaiian = 'BlueHawaiian',
  TropicalBreeze = 'TropicalBreeze',
  WatermelonLime = 'WatermelonLime',
  VanillaIce = 'VanillaIce',

  PurpleRain = 'PurpleRain',
  BlueMoon = 'BlueMoon',
  GreyGhost = 'GreyGhost',
  GreyGoose = 'GreyGoose',
  LadderBG = 'LadderBG',

  TrainerBG = 'TrainerBG',
}

export interface GradientBackgroundProps {
  height?: ScreenAware<number | string | undefined | null>;
  bgColor: GradientBackgroundColor;
  children?: React.ReactNode;
}
