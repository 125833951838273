import React, { Dispatch, SetStateAction, useState } from 'react';
import { logEvent } from 'helpers/Amplitude';
import { tagLinkClick } from 'helpers/Tealium';
import { GTMTagLinkClick } from 'helpers/GTM';
import { emitPreRegisteredUser } from 'helpers/RestAPI';
import { getEmailError } from 'helpers/Email';
import qs from 'query-string';

const analytics = async (emailCollectionUrl: string) => {
  GTMTagLinkClick('email_collection');
  await Promise.all([
    logEvent('Email Captured', {
      key: emailCollectionUrl,
    }),
    tagLinkClick('email_collection'),
  ]);
};

const signUpFlowGetEmail = async (
  emailCollectionUrl: string,
  email: string
) => {
  await analytics(emailCollectionUrl);
  await emitPreRegisteredUser(email);
  window.location.href = emailCollectionUrl;
};

const postEmail = async (
  emailCollectionUrl: string,
  email: string,
  product?: string,
  coupon?: string
) => {
  await analytics(emailCollectionUrl);
  return await emitPreRegisteredUser(email, product, coupon);
};

const addQueryParamsToUrl = (
  url: string,
  queryParams: Record<string, string>
) => {
  const urlParsed = qs.parseUrl(url);
  return qs.stringifyUrl({
    url: urlParsed.url,
    query: {
      ...urlParsed.query,
      ...queryParams,
    },
  });
};

export interface Props {
  setNeedCheckEmail: Dispatch<SetStateAction<boolean>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  product?: string;
  setProduct: Dispatch<SetStateAction<string>>;
  coupon?: string;
  setCoupon: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  emailValidationMessage: string | null;
  submitting: boolean;
  showSuccess?: boolean;
  serverError?: boolean;
  setServerError: Dispatch<SetStateAction<boolean>>;
}

export const useEmailForm = (): Props => {
  const [needCheckEmail, setNeedCheckEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [product, setProduct] = useState('');
  const [coupon, setCoupon] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [serverError, setServerError] = useState(false);
  const emailValidationMessage = getEmailError(needCheckEmail, email);
  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      const isPost = e.currentTarget.getAttribute('method') === 'post';
      const emailCollectionUrl = addQueryParamsToUrl(
        e.currentTarget.getAttribute('action') || '',
        { email, product, coupon }
      );
      e.preventDefault();

      if (submitting || !!emailValidationMessage) {
        return;
      }

      if (!email) {
        setNeedCheckEmail(true);
        return;
      }

      setSubmitting(true);

      if (!isPost) {
        await signUpFlowGetEmail(emailCollectionUrl, email);
      } else {
        try {
          await postEmail(emailCollectionUrl, email, product, coupon);
          setShowSuccess(true);
          setServerError(false);
        } catch (e) {
          setShowSuccess(false);
          setServerError(true);
        }
      }
    },
    [submitting, email, emailValidationMessage]
  );

  return {
    setNeedCheckEmail,
    setEmail,
    email,
    product,
    setProduct,
    coupon,
    setCoupon,
    handleSubmit,
    emailValidationMessage,
    submitting,
    showSuccess,
    setServerError,
    serverError,
  };
};
