import React from 'react';
import { IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ScreenAware } from 'components/Common/Placement/Placement';
import { ImageWrapper, Image } from './ResponsiveImage.styles';

export const ResponsiveImage: React.FC<{
  mobileImage?: IGatsbyImageData;
  desktopImage?: IGatsbyImageData;
  alt?: string;
  className?: string;
}> = ({
  mobileImage,
  desktopImage,
  alt = 'Responsive Background',
  className = 'ResponsiveImage',
}) => {
  if (desktopImage && mobileImage) {
    const image = withArtDirection(desktopImage as IGatsbyImageData, [
      {
        media: '(max-width: 52em)',
        image: mobileImage as IGatsbyImageData,
      },
    ]);

    return (
      <ImageWrapper
        fullWidth={image.layout === 'fullWidth'}
        mobile={{
          width: mobileImage.width,
          height: mobileImage.height,
        }}
      >
        <Image
          className={className}
          image={image}
          imgStyle={{ objectPosition: 'top center' }}
          alt={alt}
        />
      </ImageWrapper>
    );
  } else if (mobileImage || desktopImage) {
    return (
      <Image
        className={className}
        image={mobileImage || desktopImage}
        imgStyle={{ objectPosition: 'top center' }}
        alt={alt}
      />
    );
  }

  return null;
};

export const ScreenAwareResponsiveImage: React.FC<{
  images: ScreenAware<IGatsbyImageData>;
  alt?: string;
  className?: string;
}> = ({ images, alt, className }) => (
  <ResponsiveImage
    className={className}
    mobileImage={images.mobile}
    desktopImage={images.desktop}
    alt={alt}
  />
);
