import React from 'react';
import { isLadder } from 'helpers/Ladder';
import { useIsBrowser } from 'utils/general';
import { ProductTile } from 'components/Product/Tile/ProductTile';

import {
  FullProductSliderMobile,
  ProductSliderMobile,
} from './ProductSliderMobile';
import {
  ProductSliderWrapper,
  Title,
  FullProductSliderWrapper,
} from './ProductSlider.styles';
import { ProductSliderProps } from './ProductSlider.model';
import { useIsMobileOrTablet } from 'hooks/use-media-query';
import { ProductTileGrid } from 'components/Product/Tile/ProductTileGrid';

export const ProductSlider: React.FC<ProductSliderProps> = ({
  title,
  products,
}) => {
  const isBrowser = useIsBrowser();
  const isMobileOrTablet = useIsMobileOrTablet();

  // Due to a bug in the keen slider's ref logic, mobile product slider renders as blank in Safari
  // when the back button is used, which is fixed by not SSR rendering this component.
  if (!isBrowser || products.length === 0) {
    return null;
  }

  if (isLadder()) {
    return (
      <FullProductSlider
        {...{
          title,
          isBrowser,
          isMobileOrTablet,
          products,
        }}
      />
    );
  }

  return (
    <ProductSliderWrapper>
      {title && <Title>{title}</Title>}
      {isMobileOrTablet && isBrowser && products.length > 2 ? (
        <ProductSliderMobile title={title} products={products} />
      ) : (
        <ProductTileGrid>
          {products.map((product, idx) => (
            <ProductTile key={idx} {...product} />
          ))}
        </ProductTileGrid>
      )}
    </ProductSliderWrapper>
  );
};

const FullProductSlider: React.FC<
  ProductSliderProps & {
    isBrowser: boolean;
    isMobileOrTablet: boolean;
  }
> = ({ products, title, isMobileOrTablet, isBrowser }) => {
  return (
    <FullProductSliderWrapper showArrows={products.length > 4}>
      {title && <Title>{title}</Title>}
      {(isMobileOrTablet && isBrowser && products.length > 2) ||
      (isBrowser && products.length > 4) ? (
        <FullProductSliderMobile
          title={title}
          products={products}
          inRow={isMobileOrTablet ? 2 : 4}
        />
      ) : (
        <ProductTileGrid>
          {products.map((product, idx) => (
            <ProductTile key={idx} {...product} />
          ))}
        </ProductTileGrid>
      )}
    </FullProductSliderWrapper>
  );
};
