import { GradientBackgroundColor } from './Background.model';

export const getGradient = (
  variant: GradientBackgroundColor
): string | undefined => {
  return gradients[variant]?.background || undefined;
};

export const gradients = {
  [GradientBackgroundColor.PinkLemonade]: {
    background: 'linear-gradient(90deg, #FB9082 0%, #F9C3B1 100%)',
  },
  [GradientBackgroundColor.WildBerry]: {
    background: 'linear-gradient(90deg, #C473B2 0%, #FFAAAA 100%);',
  },
  [GradientBackgroundColor.MiamiVice]: {
    background: 'linear-gradient(161.73deg, #E69DE1 0%, #FFDACB 99.1%);',
  },
  [GradientBackgroundColor.StrawberryLemonade]: {
    background: 'linear-gradient(161.73deg, #DE8CFF 0%, #FFF0DD 92.28%);',
  },
  [GradientBackgroundColor.BerryMix]: {
    background: 'linear-gradient(90deg, #DF7983 0%, #CACADA 100%);',
  },
  [GradientBackgroundColor.BerryLemonade]: {
    background: 'linear-gradient(90deg, #89AAD3 0%, #F9CDBA 100%);',
  },
  [GradientBackgroundColor.MangoTango]: {
    background: 'linear-gradient(90deg, #FF8571 0%, #FFE587 100%);',
  },
  [GradientBackgroundColor.OrangeCrush]: {
    background: 'linear-gradient(90deg, #FBA92E 0%, #FFF2C5 100%);',
  },
  [GradientBackgroundColor.GrapefruitSunrise]: {
    background: 'linear-gradient(249.35deg, #FEC194 9.03%, #FE6B7D 111.14%);',
  },
  [GradientBackgroundColor.StrawberryKiwi]: {
    background: 'linear-gradient(249.35deg, #E8FFC2 9.03%, #FF9292 111.14%);',
  },
  [GradientBackgroundColor.Appletini]: {
    background: 'linear-gradient(90deg, #AAF5FF 0%, #69E090 100%);',
  },
  [GradientBackgroundColor.BlueLagoon]: {
    background: 'linear-gradient(63.02deg, #FFEE7B -12.3%, #4FDFFF 95.89%);',
  },
  [GradientBackgroundColor.Curacao]: {
    background: 'linear-gradient(64.63deg, #A5FFE1 -25.37%, #4F95FF 138.08%);',
  },
  [GradientBackgroundColor.BlueHawaiian]: {
    background: 'linear-gradient(90deg, #66BEE4 0%, #B6F0D8 100%);',
  },
  [GradientBackgroundColor.TropicalBreeze]: {
    background: 'linear-gradient(162.08deg, #AAE8DA 0%, #FFEFCF 104.69%);',
  },
  [GradientBackgroundColor.WatermelonLime]: {
    background: 'linear-gradient(135deg, #A8FAEB 0%, #FFBDBD 100%);',
  },
  [GradientBackgroundColor.VanillaIce]: {
    background: 'linear-gradient(90deg, #80B8DC 0%, #FFF2C5 100%);',
  },
  [GradientBackgroundColor.PurpleRain]: {
    background: 'linear-gradient(90deg, #7155C8 0%, #76C1FF 100%);',
  },
  [GradientBackgroundColor.BlueMoon]: {
    background: 'linear-gradient(90deg, #3190EC 0%, #CACADA 100%);',
  },
  [GradientBackgroundColor.GreyGhost]: {
    background: 'linear-gradient(90deg, #7D7E98 0%, #CCDFF1 100%);',
  },
  [GradientBackgroundColor.GreyGoose]: {
    background: 'linear-gradient(90deg, #797983 0%, #CACADA 100%);',
  },
  [GradientBackgroundColor.LadderBG]: {
    background: 'linear-gradient(90deg, #E8E8EA 0%, #F4F4F7 100%);',
  },
  [GradientBackgroundColor.TrainerBG]: {
    background: 'linear-gradient(180deg, #CCDFF1 0%, #F3F9FF 100%);',
  },
};
