import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Wrapper,
  Video,
  PlayButton,
  GradientBg,
  Content,
} from './Background.styles';
import { logError } from 'helpers/Log';
import {
  ResponsiveBackgroundImageProps,
  BackgroundVideoProps,
  GradientBackgroundProps,
} from './Background.model';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { ResponsiveImage } from '../GatsbyImage/ResponsiveImage';
import { FC } from 'react';
import { ScreenAware } from '../Placement/Placement';
import { getPaddingTopAspectRatio } from 'components/Image/Image.helper';

export const ResponsiveBackgroundImage = ({
  height,
  images,
  lockAspectRatio,
  children,
  ...rest
}: ResponsiveBackgroundImageProps) => {
  const mobileImage = getSimplifiedImageData(images.mobile);
  const desktopImage = getSimplifiedImageData(images.desktop);

  const lockedDesktopPaddingTop =
    lockAspectRatio && desktopImage
      ? getPaddingTopAspectRatio(desktopImage.width, desktopImage.height)
      : null;

  const lockedMobilePaddingTop =
    lockAspectRatio && mobileImage
      ? getPaddingTopAspectRatio(mobileImage.width, mobileImage.height)
      : null;

  return (
    <Wrapper
      height={[
        (!lockAspectRatio && height?.mobile) || 'auto',
        (!lockAspectRatio && height?.desktop) || 'auto',
      ]}
      paddingTop={
        lockAspectRatio
          ? [
              lockedMobilePaddingTop || lockedDesktopPaddingTop,
              lockedDesktopPaddingTop || lockedMobilePaddingTop,
            ]
          : undefined
      }
      {...rest}
    >
      <ResponsiveImage
        className="ResponsiveBackgroundImage"
        desktopImage={desktopImage}
        mobileImage={mobileImage}
      />
      <Content>{children}</Content>
    </Wrapper>
  );
};

export const OverlayContent = Content;

export const OverlayBackground: FC = ({ children }) => <>{children}</>;

export const Overlay: FC<{
  height?: ScreenAware<number | string | undefined | null>;
}> = ({ height, children }) => {
  return (
    <Wrapper height={[height?.mobile || 'auto', height?.desktop || 'auto']}>
      {children}
    </Wrapper>
  );
};

export const HeroBackgroundVideo = ({
  height,
  poster,
  video,
  children,
  videoTint = 0,
  ...rest
}: BackgroundVideoProps) => {
  const [ref, inView] = useInView({
    rootMargin: '-60px 0px 0px 0px', // header offset
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showPlayButton, setShowPlayButton] = useState(false);

  const play = () => videoRef?.current?.play();
  const pause = () => videoRef?.current?.pause();

  useEffect(() => {
    if (inView) {
      play()?.catch(() => setShowPlayButton(true));
    } else {
      pause();
    }
  }, [videoRef, inView]);

  return (
    <Wrapper
      ref={ref}
      className="HeroBackgroundVideo"
      height={height}
      style={{ backgroundColor: 'black' }}
      {...rest}
    >
      <Video
        ref={videoRef}
        poster={poster}
        loop
        autoPlay
        src={video}
        playsInline
        muted
        opacity={1 - videoTint / 100}
      />
      <Content>
        {children}
        {showPlayButton && (
          <PlayButton
            onClick={async () => {
              try {
                await play();
                setShowPlayButton(false);
              } catch (e) {
                logError(e);
              }
            }}
          />
        )}
      </Content>
    </Wrapper>
  );
};

export const GradientBackground = (props: GradientBackgroundProps) => {
  const { height, bgColor, children } = props;

  return (
    <GradientBg
      height={[height?.mobile || 'auto', height?.desktop || 'auto']}
      variant={bgColor}
    >
      {children}
    </GradientBg>
  );
};
