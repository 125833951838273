import React from 'react';
import {
  SanityLadderProductSliderFragment,
  SanityProductSliderFragment,
} from 'graphql-types';
import { useIsBrowser, useUserData } from '@bbnb/openfit-frontend-shared';

import { ProductSlider } from './ProductSlider';
import { getProducts } from 'components/Product/Tile/ProductTile.helper';
import { isLadder } from 'helpers/Ladder';
import { handleCrossSellClick } from 'components/Cart/CartCrossSell/CartCrossSell';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { PlaceholderContainer } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder.styles';

interface Props {
  fields: SanityProductSliderFragment | SanityLadderProductSliderFragment;
}

export const ProductSliderSanity = ({ fields }: Props) => {
  const { role, loading } = useUserData();
  const isBrowser = useIsBrowser();
  const isEntitled = role === 'subscriber';
  const products = getProducts(fields.products, isEntitled).map(
    handleCrossSellClick('Product Page')
  );

  if (loading || !isBrowser) {
    return (
      <PlaceholderContainer height={isLadder() ? '553px' : '633px'}>
        <LoadingPlaceHolder
          width={'1312px'}
          height={isLadder() ? '475px' : '445px'}
        />
      </PlaceholderContainer>
    );
  }

  return <ProductSlider title={fields.title || ''} products={products} />;
};
