import { getGclid, openfitFetch } from '@bbnb/openfit-frontend-shared';
import { getAuthorized } from './Customer';
import { getTZ } from './General';

export const SERVER_DOWN = 'Service Unavailable';
export const SERVER_DOWN_CODE = 12029;

export const emitPreRegisteredUser = async (
  email: string,
  product?: string,
  coupon?: string
) => {
  const gclid = getGclid();
  const url = `${process.env.GATSBY_REST_API_ENDPOINT}/register/pre-register`;
  const payload =
    product !== '' && coupon !== ''
      ? { email_address: email, product, coupon }
      : { email_address: email };
  const options = setJsonData(
    { ...payload, ...(gclid && { gclid }) },
    {
      method: 'POST',
    }
  );

  return await handleFetch(url, options);
};

const setJsonData = <DataType>(
  data: DataType,
  options?: RequestInit
): RequestInit => ({
  ...options,
  headers: {
    ...options?.headers,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});

export const handleFetch = async (url: RequestInfo, data?: RequestInit) => {
  try {
    const response = await fetch(url, data);

    const body = await response.json();
    if (response.ok) {
      return body;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  return {};
};

export async function getLink(): Promise<string> {
  const response = await openfitFetch<any>(
    `${process.env.GATSBY_REST_API_ENDPOINT}/auth/web_to_app`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: getAuthorized(),
        TZ: getTZ(),
      },
    }
  );

  return response.link || '';
}
