import React from 'react';
import {
  SanityImage,
  SanitySimpleMediaWithTextBlockFragment,
} from 'graphql-types';
import { ResponsiveBackgroundImage } from 'components/Common/Background';
import {
  Content,
  Title,
  Description,
} from 'components/SimpleMediaWithText/SimpleMediaWithText.styles';
import {
  Placement,
  HorizontalPlacement,
  VerticalPlacement,
} from 'components/Common/Placement/Placement';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';

export const SimpleMediaWithText = ({
  fields: {
    title,
    subtitle,
    titleMobileColor,
    titleDesktopColor,
    desktopImage,
    mobileImage,
  },
}: {
  fields: SanitySimpleMediaWithTextBlockFragment;
}) => {
  return (
    <ResponsiveBackgroundImage
      images={{
        mobile: mobileImage as SanityImage,
        desktop: desktopImage as SanityImage,
      }}
    >
      <Placement
        contentPlacement={{
          vertical: {
            desktop: VerticalPlacement.MIDDLE,
            mobile: VerticalPlacement.TOP,
          },
          horizontal: {
            desktop: HorizontalPlacement.LEFT,
            mobile: HorizontalPlacement.CENTER,
          },
        }}
      >
        <Content>
          <Title
            colorMobile={titleMobileColor?.hex as string}
            colorDesktop={titleDesktopColor?.hex as string}
          >
            {title}
          </Title>
          {subtitle && (
            <Description>
              <StandardRichText fields={subtitle} />
            </Description>
          )}
        </Content>
      </Placement>
    </ResponsiveBackgroundImage>
  );
};
